{
    "Login": "Accedi",
    "Login in to your account": "Accedi al tuo account",
    "Or": "oppure",
    "create your free account": "crea gratuitamente il tuo account",
    "Email address": "Indirizzo email",
    "Remember me": "Ricordarmi",
    "Password": "Password",
    "Forgot your password?": "Hai dimenticato la password?",
    "Recovery": "Recupera",
    "Password recovery": "Recupera password",
    "Register": "Registrati",
    "Create your free account": "Crea gratuitamente il tuo account",
    "login in to your account": "accedi al tuo account",
    "Name": "Nome",
    "Plans": "Piani",
    "Open sidebar": "Apri menu",
    "View notifications": "Mostra notifiche",
    "Open user menu": "Api menu utente",
    "Your organization": "La tua organizzazione",
    "Settings": "Impostazioni",
    "Sign out": "Disconnetti",
    "Close sidebar": "Chiudi menu",
    "Dashboard": "Dashboard",
    "Requests": "Richieste",
    "Notifications": "Notifiche",
    "Analytics": "Statistiche",
    "Organization": "Organizzazione",
    "Movements": "Movimenti",
    "Search": "Cerca",
    "Default incoming": "Indirizzo reso predefinito",
    "Allow self ship": "Consenti spedizioni",
    "Allow store return": "Consenti reso fisico",
    "New address": "Nuovo indirizzo",
    "Add": "Aggiungi",
    "Edit": "Modifica",
    "Change": "Cambia",
    "Save": "Salva",
    "Cancel": "Annulla",
    "Remove": "Rimuovi",
    "Setting": "Impostazioni",
    "delete": "Rimuovi",
    "free": "gratuito",
    "Waiting...": "Attendere...",
    "Choose your plan": "Scegli il tuo piano",
    "Continue": "Continua",
    "Step 1": "Step 1",
    "Step 2": "Step 2",
    "Step 3": "Step 3",
    "Request number": "Richiesta numero",
    "Order": "Ordine",
    "Customer": "Cliente",
    "Shipment": "Spedizione",
    "Pending decision": "Da approvare",
    "Declined": "Rifiutata",
    "Paused": "In attesa",
    "Checking goods": "Verifica della merce",
    "Waiting goods": "In attesa della merce",
    "Completed": "Completata",
    "Archivied": "Archiviata",
    "Code": "Codice",
    "Refund methods": "Modalità di reso",
    "No results": "Nessun risultato al momento disponibile.",
    "Loading": "Caricamento",
    "Your requests": "Le tue richieste"
}