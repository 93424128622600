{
    "Login": "Login",
    "Login in to your account": "Login in to your account",
    "Or": "Or",
    "create your free account": "create your free account",
    "Email address": "Email address",
    "Remember me": "Remember me",
    "Password": "Password",
    "Forgot your password?": "Forgot your password?",
    "Recovery": "Recovery",
    "Password recovery": "Password recovery",
    "Register": "Register",
    "Create your free account": "Create your free account",
    "login in to your account": "login in to your account",
    "Name": "Name",
    "Plans": "Plans",
    "Open sidebar": "Open sidebar",
    "View notifications": "View notifications",
    "Open user menu": "Open user menu",
    "Your organization": "Your organization",
    "Settings": "Settings",
    "Sign out": "Sign out",
    "Close sidebar": "Close sidebar",
    "Dashboard": "Dashboard",
    "Requests": "Requests",
    "Notifications": "Notifications",
    "Analytics": "Analytics",
    "Organization": "Organization",
    "Movements": "Movements",
    "Search": "Search",
    "Default incoming": "Default incoming",
    "Allow self ship": "Allow self ship",
    "Allow store return": "Allow store return",
    "New address": "New address",
    "Add": "Add",
    "Edit": "Edit",
    "Change": "Change",
    "Save": "Save",
    "Cancel": "Cancel",
    "Remove": "Remove",
    "Setting": "Setting",
    "Delete": "Delete",
    "free": "free",
    "Waiting...": "Waiting...",
    "Choose your plan": "Choose your plan",
    "Continue": "Continue",
    "Step 1": "Step 1",
    "Step 2": "Step 2",
    "Step 3": "Step 3",
    "Request number": "Request number",
    "Order": "Order",
    "Customer": "Customer",
    "Shipment": "Shipment",
    "Pending decision": "Pending decision",
    "Declined": "Declined",
    "Paused": "Paused",
    "Checking goods": "Checking goods",
    "Waiting goods": "Waiting goods",
    "Completed": "Completed",
    "Archivied": "Archivied",
    "Code": "Code",
    "Refund methods": "Refund methods",
    "No results": "No result currently available.",
    "Loading": "Loading",
    "Your requests": "Your requests"
}