import components_plugin_KR1HBZs4kY from "/home/sendadm/admin.sendback.com/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/home/sendadm/admin.sendback.com/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/home/sendadm/admin.sendback.com/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/home/sendadm/admin.sendback.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import composition_sLxaNGmlSL from "/home/sendadm/admin.sendback.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/sendadm/admin.sendback.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_OcT8LIvbNC from "/home/sendadm/admin.sendback.com/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import payload_client_5Om5dvb8Jc from "/home/sendadm/admin.sendback.com/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import auth_ANExhJHvcR from "/home/sendadm/admin.sendback.com/plugins/auth.js";
import bus_RrsLoRnN1Z from "/home/sendadm/admin.sendback.com/plugins/bus.ts";
export default [
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_OcT8LIvbNC,
  payload_client_5Om5dvb8Jc,
  auth_ANExhJHvcR,
  bus_RrsLoRnN1Z
]