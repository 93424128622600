import { default as loginvqUg5FFrAcMeta } from "/home/sendadm/admin.sendback.com/pages/auth/login.vue?macro=true";
import { default as recoveryCEvc1p027eMeta } from "/home/sendadm/admin.sendback.com/pages/auth/recovery.vue?macro=true";
import { default as register4eT5cGQOa3Meta } from "/home/sendadm/admin.sendback.com/pages/auth/register.vue?macro=true";
import { default as verify_45emailAY6Kdwoc8PMeta } from "/home/sendadm/admin.sendback.com/pages/auth/verify-email.vue?macro=true";
import { default as plansvzwQr3BrYYMeta } from "/home/sendadm/admin.sendback.com/pages/billing/plans.vue?macro=true";
import { default as customer_45careLLQOhSwO0sMeta } from "/home/sendadm/admin.sendback.com/pages/customer-care.vue?macro=true";
import { default as dashboard2vy8nrZHGNMeta } from "/home/sendadm/admin.sendback.com/pages/dashboard.vue?macro=true";
import { default as setupuMaoc2IDwEMeta } from "/home/sendadm/admin.sendback.com/pages/integrations/magento/setup.vue?macro=true";
import { default as preferencesFCOUSP6LacMeta } from "/home/sendadm/admin.sendback.com/pages/integrations/shopify/[id]/preferences.vue?macro=true";
import { default as setup2M6DU1rgn4Meta } from "/home/sendadm/admin.sendback.com/pages/integrations/shopify/setup.vue?macro=true";
import { default as splashUoSFFskoa6Meta } from "/home/sendadm/admin.sendback.com/pages/integrations/shopify/splash.vue?macro=true";
import { default as movements1s5FR4jcy6Meta } from "/home/sendadm/admin.sendback.com/pages/movements.vue?macro=true";
import { default as notificationsfs5PWvGpzBMeta } from "/home/sendadm/admin.sendback.com/pages/notifications.vue?macro=true";
import { default as setting4GLR40RXGGMeta } from "/home/sendadm/admin.sendback.com/pages/onboarding/setting.vue?macro=true";
import { default as organizationPJok9H0PWVMeta } from "/home/sendadm/admin.sendback.com/pages/organization.vue?macro=true";
import { default as _91id_93eZwEdTS9GKMeta } from "/home/sendadm/admin.sendback.com/pages/request/[id].vue?macro=true";
import { default as requestsK2Riq8ZbbeMeta } from "/home/sendadm/admin.sendback.com/pages/requests.vue?macro=true";
import { default as settingsnDCtyTScFXMeta } from "/home/sendadm/admin.sendback.com/pages/settings.vue?macro=true";
export default [
  {
    name: loginvqUg5FFrAcMeta?.name ?? "auth-login",
    path: loginvqUg5FFrAcMeta?.path ?? "/auth/login",
    children: [],
    meta: loginvqUg5FFrAcMeta || {},
    alias: loginvqUg5FFrAcMeta?.alias || [],
    redirect: loginvqUg5FFrAcMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: recoveryCEvc1p027eMeta?.name ?? "auth-recovery",
    path: recoveryCEvc1p027eMeta?.path ?? "/auth/recovery",
    children: [],
    meta: recoveryCEvc1p027eMeta || {},
    alias: recoveryCEvc1p027eMeta?.alias || [],
    redirect: recoveryCEvc1p027eMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/auth/recovery.vue").then(m => m.default || m)
  },
  {
    name: register4eT5cGQOa3Meta?.name ?? "auth-register",
    path: register4eT5cGQOa3Meta?.path ?? "/auth/register",
    children: [],
    meta: register4eT5cGQOa3Meta || {},
    alias: register4eT5cGQOa3Meta?.alias || [],
    redirect: register4eT5cGQOa3Meta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailAY6Kdwoc8PMeta?.name ?? "auth-verify-email",
    path: verify_45emailAY6Kdwoc8PMeta?.path ?? "/auth/verify-email",
    children: [],
    meta: verify_45emailAY6Kdwoc8PMeta || {},
    alias: verify_45emailAY6Kdwoc8PMeta?.alias || [],
    redirect: verify_45emailAY6Kdwoc8PMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: plansvzwQr3BrYYMeta?.name ?? "billing-plans",
    path: plansvzwQr3BrYYMeta?.path ?? "/billing/plans",
    children: [],
    meta: plansvzwQr3BrYYMeta || {},
    alias: plansvzwQr3BrYYMeta?.alias || [],
    redirect: plansvzwQr3BrYYMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/billing/plans.vue").then(m => m.default || m)
  },
  {
    name: customer_45careLLQOhSwO0sMeta?.name ?? "customer-care",
    path: customer_45careLLQOhSwO0sMeta?.path ?? "/customer-care",
    children: [],
    meta: customer_45careLLQOhSwO0sMeta || {},
    alias: customer_45careLLQOhSwO0sMeta?.alias || [],
    redirect: customer_45careLLQOhSwO0sMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: dashboard2vy8nrZHGNMeta?.name ?? "dashboard",
    path: dashboard2vy8nrZHGNMeta?.path ?? "/dashboard",
    children: [],
    meta: dashboard2vy8nrZHGNMeta || {},
    alias: dashboard2vy8nrZHGNMeta?.alias || [],
    redirect: dashboard2vy8nrZHGNMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: setupuMaoc2IDwEMeta?.name ?? "integrations-magento-setup",
    path: setupuMaoc2IDwEMeta?.path ?? "/integrations/magento/setup",
    children: [],
    meta: setupuMaoc2IDwEMeta || {},
    alias: setupuMaoc2IDwEMeta?.alias || [],
    redirect: setupuMaoc2IDwEMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/integrations/magento/setup.vue").then(m => m.default || m)
  },
  {
    name: preferencesFCOUSP6LacMeta?.name ?? "integrations-shopify-id-preferences",
    path: preferencesFCOUSP6LacMeta?.path ?? "/integrations/shopify/:id/preferences",
    children: [],
    meta: preferencesFCOUSP6LacMeta || {},
    alias: preferencesFCOUSP6LacMeta?.alias || [],
    redirect: preferencesFCOUSP6LacMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/integrations/shopify/[id]/preferences.vue").then(m => m.default || m)
  },
  {
    name: setup2M6DU1rgn4Meta?.name ?? "integrations-shopify-setup",
    path: setup2M6DU1rgn4Meta?.path ?? "/integrations/shopify/setup",
    children: [],
    meta: setup2M6DU1rgn4Meta || {},
    alias: setup2M6DU1rgn4Meta?.alias || [],
    redirect: setup2M6DU1rgn4Meta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/integrations/shopify/setup.vue").then(m => m.default || m)
  },
  {
    name: splashUoSFFskoa6Meta?.name ?? "integrations-shopify-splash",
    path: splashUoSFFskoa6Meta?.path ?? "/integrations/shopify/splash",
    children: [],
    meta: splashUoSFFskoa6Meta || {},
    alias: splashUoSFFskoa6Meta?.alias || [],
    redirect: splashUoSFFskoa6Meta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/integrations/shopify/splash.vue").then(m => m.default || m)
  },
  {
    name: movements1s5FR4jcy6Meta?.name ?? "movements",
    path: movements1s5FR4jcy6Meta?.path ?? "/movements",
    children: [],
    meta: movements1s5FR4jcy6Meta || {},
    alias: movements1s5FR4jcy6Meta?.alias || [],
    redirect: movements1s5FR4jcy6Meta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/movements.vue").then(m => m.default || m)
  },
  {
    name: notificationsfs5PWvGpzBMeta?.name ?? "notifications",
    path: notificationsfs5PWvGpzBMeta?.path ?? "/notifications",
    children: [],
    meta: notificationsfs5PWvGpzBMeta || {},
    alias: notificationsfs5PWvGpzBMeta?.alias || [],
    redirect: notificationsfs5PWvGpzBMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: setting4GLR40RXGGMeta?.name ?? "onboarding-setting",
    path: setting4GLR40RXGGMeta?.path ?? "/onboarding/setting",
    children: [],
    meta: setting4GLR40RXGGMeta || {},
    alias: setting4GLR40RXGGMeta?.alias || [],
    redirect: setting4GLR40RXGGMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/onboarding/setting.vue").then(m => m.default || m)
  },
  {
    name: organizationPJok9H0PWVMeta?.name ?? "organization",
    path: organizationPJok9H0PWVMeta?.path ?? "/organization",
    children: [],
    meta: organizationPJok9H0PWVMeta || {},
    alias: organizationPJok9H0PWVMeta?.alias || [],
    redirect: organizationPJok9H0PWVMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/organization.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eZwEdTS9GKMeta?.name ?? "request-id",
    path: _91id_93eZwEdTS9GKMeta?.path ?? "/request/:id",
    children: [],
    meta: _91id_93eZwEdTS9GKMeta || {},
    alias: _91id_93eZwEdTS9GKMeta?.alias || [],
    redirect: _91id_93eZwEdTS9GKMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/request/[id].vue").then(m => m.default || m)
  },
  {
    name: requestsK2Riq8ZbbeMeta?.name ?? "requests",
    path: requestsK2Riq8ZbbeMeta?.path ?? "/requests",
    children: [],
    meta: requestsK2Riq8ZbbeMeta || {},
    alias: requestsK2Riq8ZbbeMeta?.alias || [],
    redirect: requestsK2Riq8ZbbeMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/requests.vue").then(m => m.default || m)
  },
  {
    name: settingsnDCtyTScFXMeta?.name ?? "settings",
    path: settingsnDCtyTScFXMeta?.path ?? "/settings",
    children: [],
    meta: settingsnDCtyTScFXMeta || {},
    alias: settingsnDCtyTScFXMeta?.alias || [],
    redirect: settingsnDCtyTScFXMeta?.redirect || undefined,
    component: () => import("/home/sendadm/admin.sendback.com/pages/settings.vue").then(m => m.default || m)
  }
]