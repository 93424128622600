<script setup>
    import { useI18n } from "vue-i18n";
    const { t } = useI18n();

    definePageMeta({
        middleware: "guest",
        layout: "guest",
    });

    useHead({
        title: t("Verify email"),
        htmlAttrs: {
            class: "h-full bg-white",
        },
        bodyAttrs: {
            class: "h-full",
        },
    });
</script>
